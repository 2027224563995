/*
Author:      Dimitra Weinstein
Created:     12/27/2022
Modified:    12/27/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

.tooltip {
    position: absolute;
    height: fit-content;
    width: 250px;
    margin-left: -125px;
    margin-top: -250px;
    left: -50% !important;
    z-index: 500 !important;
    text-align: center;
    background: var(--white);
    padding: 5px;
    border-radius: 5%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

