/*
Author:      Zachary Thomas
Created:     5/14/2021
Modified:    12/29/2022

Copyright 2021 - 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

body {
  --primary-background: #1265b2;
  --dev-background: #1265b2;
  --prod-background: #b41e1e;
  --logo-background: #083156;
  --page-title: #212121;
  --title: #fff;
  --title-hover: #e5e5e5;
  --text-active: #fff;
  --text-inactive: #ffffffaa;
  --text-hover: #ffffffdd;
  --content-text: #141414;
  --muted-text: #212121;
  --marker-satellite: #2c7bc5;
  --marker-email: #eb9636;
  --marker-hover-email: #b56b16;
  --tab-inactive-dev: #0b3e6d;
  --tab-hover-dev: #0F5290;
  --tab-active-prod: #b41e1e;
  --tab-inactive-prod: #731313;
  --tab-hover-prod: #901818;
  --background-far: #f0f3f4;
  --background-close: #fff;
  --background-hover: #f0f3f4;
  --link-background-hover: #e0e8f7;
  --border: #0000001f;
  --border-light: #0000000f;
  --border-dark: #0000003f;
  --link: #0645AD;
  --chart-background: #f2f2f2;
  --chart-background-dark: #e7e7e7;
  --chart-background-darker: #999;
  --white: #fff;
  --black: #000;
  --information: #28a745;
  --verbose: #007bff;
  --business: #474281;
  --exception: #e67c05;
  --alert: #dc3545;
  --information-light: #52b86a;
  --verbose-light: #3295ff;
  --business-light: #6b679a;
  --exception-light: #eb9636;
  --alert-light: #e35d6a;
  --form-container: #eaeded;
  --light-button-hover: #cbcfd2;
  --resource-hover: #f4f7fc;
  --resource-select: #e0e8f7;
  --alert-success: #d4edda;
  --alert-error: #f8d7da;
  --light-alert-success: #e5f4e8;
  --light-alert-error: #fae7e8;
  --table-items-unselected: #cbcfd2;
  --table-header: #ddd;
}

html,
body {
  height: 100% !important;
  background-color: var(--background-far) !important;
}

main {
  flex: 1 0 auto;
  min-height: 100vh;
}

.selectable-container {
  color: var(--link);
}

.selectable-container:hover {
  cursor: pointer;
  text-decoration: underline;
  background-color: var(--link-background-hover);
}

.font-weight-bold {
  font-weight: 600;
}

.btn.btn-success {
  color: #fff;
}

.btn.btn-success:hover {
  color: #fff;
}

.selectable-container {
  color: var(--link);
}

.selectable-container:hover {
  cursor: pointer !important;
  text-decoration: underline !important;
  background-color: var(--link-background-hover) !important;
}

.btn-light:hover {
  background-color: var(--light-button-hover) !important;
  border-color: var(--light-button-hover) !important;
}