/*
Author:      Dimitra Weinstein
Created:     12/21/2022
Modified:    12/29/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

.table {
    position: absolute;
    margin-top: -40vh;
    margin-left: 325px;
    padding: 35px;
    border: 2px solid var(--primary-background);
    text-align: center;
    width: 225px;
    height: 75px;
    z-index: 50;
}

.thead {
    height: 35px;
    background-color: var(--primary-background);
    color: var(--title);
    font-size: 1.5em;
}

.tbody {
    background-color: var(--background-close);
}

.td {
    font-size: 1.25em;
    font-weight: 400;
}