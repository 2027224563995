/*
Author:      Dimitra Weinstein
Created:     12/20/2022
Modified:    12/20/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

.mapWrapper {
    height: 85vh;
    width: 100%;
    margin: 0;
    margin-top: 35px;
    padding: 0;
}