/*
Author:      Dimitra Weinstein
Created:     12/21/2022
Modified:    12/29/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

.body {
    position: relative;
    bottom: 28px;
  
    .wrapper {
      position: absolute;
      z-index: 50;
  
      .marker {
        width: 40px;
        height: 40px;
        border-radius: 50% 50% 50% 0;
        background: var(--marker-satellite);
        transform: rotate(-45deg);
        left: 50%;
        top: 50%;
        margin: -20px 0 0 -20px;
        cursor: pointer;
  
        .markerDetails {
          width: 32px;
          height: 32px;
          margin: 4px 0 0 4px;
          background: var(--white);
          position: absolute;
          border-radius: 50%;
          cursor: pointer;
  
          .icon {
            font-size: 25px;
            color: var(--black);
            padding: 4px;
            cursor: pointer;
          }
  
          .icon.hover {
            font-size: 32px;
          }
        }
  
        .markerDetails.hover {
          width: 40px;
          height: 40px;
          margin: 5px 0 0 5px;
        }
      }

      .markerEmailAddress {
        width: 40px;
        height: 40px;
        border-radius: 50% 50% 50% 0;
        background: var(--marker-email);
        transform: rotate(-45deg);
        left: 50%;
        top: 50%;
        margin: -20px 0 0 -20px;
        cursor: pointer;
  
        .markerDetails {
          width: 32px;
          height: 32px;
          margin: 4px 0 0 4px;
          background: var(--white);
          position: absolute;
          border-radius: 50%;
          cursor: pointer;
  
          .icon {
            font-size: 25px;
            color: var(--black);
            padding: 4px;
            cursor: pointer;
          }
  
          .icon.hover {
            font-size: 32px;
          }
        }
  
        .markerDetails.hover {
          width: 40px;
          height: 40px;
          margin: 5px 0 0 5px;
        }
      }
  
      .marker.satellite {
        background: var(--marker-satellite);
      }
  
      .marker.hover {
        width: 52px;
        height: 52px;
        background: var(--tab-hover-dev);
        border: solid .12px white;
        margin: -31px 0 0 -26px;
      }

      .markerEmailAddress.hover {
        width: 52px;
        height: 52px;
        background: var(--marker-hover-email);
        border: solid .12px white;
        margin: -31px 0 0 -26px;
      }
  
      .marker.hover.satellite {
        background: var(--tab-hover-dev);
      }
    }
  
    .wrapper:hover {
      position: absolute;
      z-index: 100;
    }
  }
  
  .details {
    .title {
      font-weight: bold;
      font-size: 1rem;
    }
  
    .attribute {
      font-style: italic;
      font-size: .85rem;
    }
  
    .info {
      margin: 0.5rem 0;
      line-height: 1rem;
    }
  }