/*
Author:      Dimitra Weinstein
Created:     12/21/2022
Modified:    12/21/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

.body {
    position: relative;
    width: 100%;
    height: 100%;
}